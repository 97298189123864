<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="submit" enctype="multipart/form-data">
      <v-container>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              name="Entity Name"
              rules="required"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="entity_name"
                :error-messages="errors"
                label="Entity Name"
                required
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="Phone Number"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="phone_number"
                :error-messages="errors"
                label="Phone Number"
                required
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              name="Point of Contact"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="point_of_contact"
                :error-messages="errors"
                label="Point of Contact"
                required
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="Email"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="email"
                :error-messages="errors"
                label="Email"
                required
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              name="Description of services"
              :rules="{
                required: true,
              }"
            >
              <v-textarea
                v-model="description"
                :error-messages="errors"
                height="50"
                hide-details=""
                filled
                label="Description of services"
              ></v-textarea>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              name="Postal Address"
              rules="required"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="postal_address"
                :error-messages="errors"
                label="Postal Address"
                required
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="Physical Address"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="physical_address"
                :error-messages="errors"
                label="Physical Address"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Postal City"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="postal_city"
                :error-messages="errors"
                label="Postal City"
                required
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              name="Physical City"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="physical_city"
                :error-messages="errors"
                label="Physical City"
                required
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="Postal Zip Code"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="postal_zipcode"
                :error-messages="errors"
                label="Postal Zip Code"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Physical Zip Coden"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="physical_zipcode"
                :error-messages="errors"
                label="Physical Zip Code"
                required
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              name="Image path"
              :rules="{
                required: false,
              }"
            >
              <v-text-field
                dense
                outlined
                :disabled="loadings.formBtn"
                v-model="image_path"
                :error-messages="errors"
                label="Image path"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col md="4">
            <validation-provider
              v-slot="{ errors }"
              name="Is Active"
              :rules="{
                required: true,
              }"
            >
              <v-select
                dense
                :disabled="loadings.formBtn"
                v-model="is_active"
                :error-messages="errors"
                label="Is Active"
                outlined
                :items="[
                  {
                    text: 'Active',
                    value: 1
                  }
                ]"
              >
              </v-select>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              class="mr-4"
              type="submit"
              :disabled="invalid"
              :loading="loadings.formBtn"
            >
              {{ actionBtnText }}
            </v-btn>
            <v-btn> clear </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </validation-observer>
</template>

<script>
import { required, digits, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapActions, mapGetters } from 'vuex';

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} not is valid",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    // formData: {
    //   entity_name: null,
    //   phone_number: null,
    //   point_of_contact: null,
    //   email: null,
    //   description: null,
    //   postal_address: null,
    //   physical_address: null,
    //   postal_city: null,
    //   physical_city: null,
    //   postal_zipcode: null,
    //   physical_zipcode: null,
    //   image_path: null,
    //   is_active: null,
    // },
    loadings: {
      formBtn: false,
    },
  }),
  computed: {
    ...mapGetters(["currentTenant", "supplierFormData"]),
    actionBtnText() {
      const self = this;
      let text = "";
      text = self.supplierFormData && self.supplierFormData.id ? "update" : "create";
      return text;
    },
    entity_name: {
      get() {
        return this.$store.getters["supplierFormData"]["entity_name"];
      },
      set(value) {
        let data = {
          key: "entity_name",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
    phone_number: {
      get() {
        return this.$store.getters["supplierFormData"]["phone_number"];
      },
      set(value) {
        let data = {
          key: "phone_number",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
    point_of_contact: {
      get() {
        return this.$store.getters["supplierFormData"]["point_of_contact"];
      },
      set(value) {
        let data = {
          key: "point_of_contact",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
    email: {
      get() {
        return this.$store.getters["supplierFormData"]["email"];
      },
      set(value) {
        let data = {
          key: "email",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
    description: {
      get() {
        return this.$store.getters["supplierFormData"]["description"];
      },
      set(value) {
        let data = {
          key: "description",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
    postal_address: {
      get() {
        return this.$store.getters["supplierFormData"]["postal_address"];
      },
      set(value) {
        let data = {
          key: "postal_address",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
    physical_address: {
      get() {
        return this.$store.getters["supplierFormData"]["physical_address"];
      },
      set(value) {
        let data = {
          key: "physical_address",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
    postal_city: {
      get() {
        return this.$store.getters["supplierFormData"]["postal_city"];
      },
      set(value) {
        let data = {
          key: "postal_city",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
    physical_city: {
      get() {
        return this.$store.getters["supplierFormData"]["physical_city"];
      },
      set(value) {
        let data = {
          key: "physical_city",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
    postal_zipcode: {
      get() {
        return this.$store.getters["supplierFormData"]["postal_zipcode"];
      },
      set(value) {
        let data = {
          key: "postal_zipcode",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
    physical_zipcode: {
      get() {
        return this.$store.getters["supplierFormData"]["physical_zipcode"];
      },
      set(value) {
        let data = {
          key: "physical_zipcode",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
    image_path: {
      get() {
        return this.$store.getters["supplierFormData"]["image_path"];
      },
      set(value) {
        let data = {
          key: "image_path",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
    is_active: {
      get() {
        return this.$store.getters["supplierFormData"]["is_active"];
      },
      set(value) {
        let data = {
          key: "is_active",
          data: value,
        };
        this.$store.commit("setSupplierFormData", data);
      },
    },
  },
  methods: {
    ...mapActions([
      'getSupplierById',
      'createSupplier',
      'updateSupplier',
    ]),
    submit() {
      const self = this;
      const valid = this.$refs.observer.validate();

      if (!valid) return;

      /* Check if create or update */
      let supplier = self.supplierFormData

      supplier.tenant_id = self.$route.params.tenant_id


      let action = self.supplierFormData? "Updating" : "Creating";
      self.showNotification({
        type: 3,
        text: `${action} Tenant`,
        persistent: true,
      });
      if (self.supplierFormData && self.supplierFormData.id) {
        /* Update */
        self
          .updateSupplier(supplier)
          .then((suss) => {
            self.showNotification({
              type: 1,
              text: suss.message,
              lngKey: false,
            });
          })
          .catch((err) => {
              this.$swal.fire({
                  timer: 4000,
                  icon: "error",
                  title: "Supplier Update Failed",
                  text: err,
                  toast: true,
                  position: "bottom-end",
                  timerProgressBar: true,
                  showConfirmButton: false,
                  showCloseButton: true,
              });
          })
          .finally(() => {
            self.closeNotifications(3);
          });
        
      } else {
        /* Create */
        self
          .createSupplier(supplier)
          .then((suss) => {
            self.showNotification({
              type: 1,
              text: suss.message,
              lngKey: false,
            });
            // self.$nextTick(() => {
            //   self.$store.commit("setCurrentTenant", suss.data);
            //   self.$router.push({ path: `/tenant/manage/${suss.data.id}` })
            // });
          })
          .catch((err) => {
            self.$swal.fire({
              timer: 4000,
              icon: "error",
              title: err.statusText,
              text: err.data.message || "Error trying to store Supplier Info",
            });
          })
          .finally(() => {
            self.closeNotifications(3);
          });
      }
    },
  },

  created() {

  }
};
</script>
