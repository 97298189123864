<template>
  <v-card min-height="60vh">
    <v-card-title>
      <v-tabs v-model="tabs"  align-with-title>
          <v-tab v-for="item in items" :key="item.tab">
            {{ item.content }}
          </v-tab>
      </v-tabs>
    </v-card-title>
    <v-container>
      <v-divider></v-divider>
    </v-container>
    <v-expand-transition>
      <v-card-text>
        <v-tabs-items v-model="tabs">
          <v-tab-item v-for="item in items" :key="item.tab"> 
            <v-card flat>
              <v-card-text>
                <component type="supplier" :is="TabsComponent(item)"></component>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import SupplierForm from "@/components/supplier/SupplierForm.vue";
import TenantForm from "@/components/tenant/TenantForm.vue";
import ModulesConfig from '../../components/tenant/ModulesConfig.vue';
// import TenantUsers from '../../components/tenant/TenantUsers.vue'
import TenantUserList from '../../components/users/TenantUserList.vue';
// import ClientList from "@/components/supplier/ClientList.vue";
import { mapActions } from 'vuex';
export default {
  data: () => ({
    tabs: null,
    items: [
      { tab: "tenant_info", content: "Tenant Information" },
      { tab: "supplier_information", content: "Supplier Information" },
      { tab: "supplier_modules", content: "Modules" },
      { tab: "supplier_users", content: "Users" },
      // { tab: "attachmen", content: "Attachments" },
      // { tab: "categories_products", content: "Categories & Products" },
      // { tab: "price_list", content: "Price List" },
      // { tab: "credit_params", content: "Credit Params" },
      // { tab: "client_list", content: "Client List" },
    ],
  }),
  computed: 
    {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions([
      'getTenantsById'
    ]),
    TabsComponent(item = null) {
      if (item.tab == 'tenant_info') return TenantForm;
      if (item.tab == 'supplier_information') return SupplierForm;
      if (item.tab == 'supplier_modules') return ModulesConfig;
      if (item.tab == 'supplier_users') return TenantUserList;
      // if (item.tab == 'credit_params') return ClientList;
      // if (item.tab == 'client_list') return ClientList;
    }
  },
  created() {
    const self = this;
    const param = self.$route.params;

    if (param && param.tenant_id) {
      self.getTenantsById(param).then((tenant) => {
        if (tenant.id) {
          self.$store.commit('setCurrentTenant', tenant);
        }
      });
    } else {
      self.$store.commit('clearTenantFrmData');
    }
  },
};
</script>