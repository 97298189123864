var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Entity Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":_vm.loadings.formBtn,"error-messages":errors,"label":"Entity Name","required":""},model:{value:(_vm.entity_name),callback:function ($$v) {_vm.entity_name=$$v},expression:"entity_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Phone Number","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":_vm.loadings.formBtn,"error-messages":errors,"label":"Phone Number","required":""},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"Point of Contact","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":_vm.loadings.formBtn,"error-messages":errors,"label":"Point of Contact","required":""},model:{value:(_vm.point_of_contact),callback:function ($$v) {_vm.point_of_contact=$$v},expression:"point_of_contact"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":_vm.loadings.formBtn,"error-messages":errors,"label":"Email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Description of services","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{attrs:{"error-messages":errors,"height":"50","hide-details":"","filled":"","label":"Description of services"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Postal Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":_vm.loadings.formBtn,"error-messages":errors,"label":"Postal Address","required":""},model:{value:(_vm.postal_address),callback:function ($$v) {_vm.postal_address=$$v},expression:"postal_address"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Physical Address","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":_vm.loadings.formBtn,"error-messages":errors,"label":"Physical Address","required":""},model:{value:(_vm.physical_address),callback:function ($$v) {_vm.physical_address=$$v},expression:"physical_address"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Postal City","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":_vm.loadings.formBtn,"error-messages":errors,"label":"Postal City","required":""},model:{value:(_vm.postal_city),callback:function ($$v) {_vm.postal_city=$$v},expression:"postal_city"}})]}}],null,true)})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":"Physical City","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":_vm.loadings.formBtn,"error-messages":errors,"label":"Physical City","required":""},model:{value:(_vm.physical_city),callback:function ($$v) {_vm.physical_city=$$v},expression:"physical_city"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Postal Zip Code","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":_vm.loadings.formBtn,"error-messages":errors,"label":"Postal Zip Code","required":""},model:{value:(_vm.postal_zipcode),callback:function ($$v) {_vm.postal_zipcode=$$v},expression:"postal_zipcode"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Physical Zip Coden","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":_vm.loadings.formBtn,"error-messages":errors,"label":"Physical Zip Code","required":""},model:{value:(_vm.physical_zipcode),callback:function ($$v) {_vm.physical_zipcode=$$v},expression:"physical_zipcode"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Image path","rules":{
              required: false,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":_vm.loadings.formBtn,"error-messages":errors,"label":"Image path"},model:{value:(_vm.image_path),callback:function ($$v) {_vm.image_path=$$v},expression:"image_path"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Is Active","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"dense":"","disabled":_vm.loadings.formBtn,"error-messages":errors,"label":"Is Active","outlined":"","items":[
                {
                  text: 'Active',
                  value: 1
                }
              ]},model:{value:(_vm.is_active),callback:function ($$v) {_vm.is_active=$$v},expression:"is_active"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid,"loading":_vm.loadings.formBtn}},[_vm._v(" "+_vm._s(_vm.actionBtnText)+" ")]),_c('v-btn',[_vm._v(" clear ")])],1)],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }